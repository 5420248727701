@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body{
  height: 100%;
}

html {
  background: url("/static/media/ravensu background.d89fba4e.jpg") no-repeat center center fixed;
  height: 100%;
  margin: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title{
  align-items: center;
  align-content: center;
  text-align: center;
}

.ravensu{
    display: inline;
    color: white;
    font-family: 'Permanent Marker', cursive;
    font-size: 75px;
}

#su{
    color: gray;
}
.welcome{
    color: white;
    font-family: 'Permanent Marker', cursive;
}
