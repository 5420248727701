html {
  background: url("./img/ravensu background.jpg") no-repeat center center fixed;
  height: 100%;
  margin: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title{
  align-items: center;
  align-content: center;
  text-align: center;
}
